<template>
  <GeneralCategory
    :return-route="adminRoute"
    :category-name="categoryName"
    :rest-end-point="restEndPoint"
  />
</template>

<script>
import GeneralCategory from '../generalCategories/GeneralCategories'
import ecosystemDefaults from './constants'

export default {
  name: 'AdminEcosystemCategories',
  components: {
    GeneralCategory
  },
  data () {
    return { ...ecosystemDefaults }
  }
}
</script>
